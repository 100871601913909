import { ColumnDef, Row } from "@tanstack/react-table";

import { useMemo } from "react";
import Trade from "models/Trade";
import { DateWithIcon } from "components/ui/icons/Date";
import { StatusBadge } from "components/ui/Badge";
import consts from "utils/consts";

const usePortfolioTableColumns = () => {
  const columns = useMemo(() => {
    const cols: ColumnDef<Trade>[] = [
      {
        id: "side",
        header: "Type",
        accessorKey: "type",
        size: 200,
        cell: ({ row }: { row: Row<Trade> }) => <StatusBadge color={row.original.type === "buy" ? "green" : "red" } text={row.original.type} />
      },
      {
        id: "token1",
        header: "Name",
        accessorKey: "token1.name",
        size: 200,
      },
      {
        id: "amount1",
        header: "Amount",
        accessorKey: "buy_amount_1",
        size: 200,
        cell: ({ row }: { row: Row<Trade> }) => 
          <span className={row.original.type === "buy" ? "text-green-700" : "text-red-700"}>
            {row.original.type === "buy" ? "+" : "-"}
            {row.original.buy_amount_1}
          </span>
      },
      {
        id: "amount2",
        header: "Price",
        accessorKey: "buy_amount_2",
        size: 200,
        cell: ({ row }: { row: Row<Trade> }) =>
          <span className={row.original.type === "sell" ? "text-green-700" : "text-red-700"}>
            {row.original.type === "sell" ? "+" : "-"}
            {row.original.buy_amount_2} {consts.defaultCurrencySymbol}
          </span>
      },
      {
        id: "date",
        header: "Date",
        accessorKey: "created_at",
        size: 200,
        cell: ({ row }: { row: Row<Trade> }) => <DateWithIcon date={row.original.created_at} />
      },
    ];

    return cols;
  }, []);

  return columns;
};

export default usePortfolioTableColumns;
