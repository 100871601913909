import Page from "components/Layout/Page";
import Tabs from "components/ui/Tabs";
import React, { useMemo, useState } from "react";
import YourAssets from "./YourAssets/YourAssets";
import Trades from "./Trades/Trades";
import OpenOrders from "./OpenOrders/OpenOrders";
import Payments from "screens/Issuance/Payments/Payments";

interface Props {}

const PortfolioColombia: React.FC<Props> = () => {
  const tabs = useMemo(
    () => [
      { label: "Your Assets", content: <YourAssets /> },
      { label: "Trades", content: <Trades /> },
      { label: "Open Orders", content: <OpenOrders /> },
      { label: "Payments", content: <Payments /> },
    ],
    []
  );
  const [activeTab, setActiveTab] = useState(tabs[0].label);

  return (
    <Page>
      <Tabs
        tabs={tabs}
        activeTab={activeTab}
        onTabClick={(tab) => setActiveTab(tab)}
      />
    </Page>
  );
};

export default PortfolioColombia;
