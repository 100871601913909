import api from "@/api";
import { useMutation } from "@tanstack/react-query";
import { ColumnDef, Row } from "@tanstack/react-table";
import { StatusBadge, StatusBadgeColor } from "components/ui/Badge";
import Button from "components/ui/Button";
import { InvestorDistributions } from "models/Investor";
import { useMemo } from "react";
import { useSelector } from "react-redux";
import consts from "utils/consts";
import { userSelector } from "utils/redux/user/userSlice";

const getStatusColor = (status: InvestorDistributions["status"]): StatusBadgeColor => {
  switch (status) {
    case "recorded":
    case "distributing":
    case "collecting":
      return "yellow";
    default:
      return "green";
  }
};

interface DistributionButtonProps {
  balance: number;
  row: Row<InvestorDistributions>;
  onDistributionSuccess: () => void;
}

const DistributionButton = ({ balance, row, onDistributionSuccess }: DistributionButtonProps) => {
  const user = useSelector(userSelector);
  
  const acceptDistributionMutation = useMutation(
    async (bondId: string) => {
      await api.put(`bonds/${bondId}/investors/${user!.id}/distribute/accept`);
    },
    {
      onSuccess: () => onDistributionSuccess()
    }
  );

  return (
    <Button 
      className="mr-[440px] w-[350px]" 
      disabled={balance < row.original.price}
      loading={acceptDistributionMutation.isLoading}
      onClick={() => acceptDistributionMutation.mutate(row.original.bond.id)}
    >
      Accept {row.original.amount} by paying {row.original.price} {consts.defaultCurrencySymbol}
    </Button>
  );
}

const useDistributionsTableColumns = (balance: number, onDistributionSuccess: () => void) => {
  const columns = useMemo(() => {
    const cols: ColumnDef<InvestorDistributions>[] = [
      {
        id: "bondIsin",
        header: "ISIN",
        accessorKey: "bond.ISIN",
        size: 277,
      },
      {
        id: "name",
        header: "Name",
        accessorKey: "bond.token.name",
        size: 277,
      },
      {
        id: "status",
        header: "Status",
        size: 200,
        cell: ({ row }: { row: Row<InvestorDistributions> }) => <StatusBadge color={getStatusColor(row.original.bond.status)} text={row.original.bond.status}></StatusBadge>
      },
      {
        id: "balance",
        header: "",
        accessorKey: "balance",
        size: 350,
        cell: ({ row }: { row: Row<InvestorDistributions> }) => (
          <DistributionButton balance={balance} row={row} onDistributionSuccess={onDistributionSuccess} />
        )
      },
    ];

    return cols;
  }, [balance]); 

  return columns;
};

export default useDistributionsTableColumns;
