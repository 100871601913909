import { ColumnDef, Row } from "@tanstack/react-table";
import { DateWithIcon } from "components/ui/icons/Date";
import { InvestorSecurities } from "models/Investor";

import { useMemo } from "react";
import consts from "utils/consts";

const usePortfolioTableColumns = (noAction?: boolean) => {
  const columns = useMemo(() => {
    const cols: ColumnDef<InvestorSecurities>[] = [
      {
        id: "bondIsin",
        header: "ISIN",
        accessorKey: "ISIN",
        size: 200,
      },
      {
        id: "name",
        header: "Name",
        accessorKey: "token.name",
        size: 200,
      },
      {
        id: "balance",
        header: "Amount",
        accessorKey: "balance",
        size: 150,
      },
      {
        id: "nominalValue",
        header: "Nominal Price",
        accessorKey: "nominal_value",
        size: 150,
        cell: ({ row }: { row: Row<InvestorSecurities> }) => `${row.original.nominal_value} ${consts.defaultCurrencySymbol}`
      },
      {
        id: "interestRate",
        header: "Interest Rate",
        size: 150,
        cell: ({ row }: { row: Row<InvestorSecurities> }) => `${row.original.annual_coupon_rate}%`
      },
      {
        id: "maturityDate",
        header: "Maturity Date",
        size: 150,
        cell: ({ row }: { row: Row<InvestorSecurities> }) => <DateWithIcon date={row.original.maturity_date} />
      },
      {
        id: "date",
        header: "Created Date",
        size: 150,
        cell: ({ row }: { row: Row<InvestorSecurities> }) => <DateWithIcon date={row.original.created_at} />
      },
    ];

    return cols;
  }, []);

  return columns;
};

export default usePortfolioTableColumns;
