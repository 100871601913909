import Card from "components/Layout/Card";
import React from "react";
import { Table } from "components/ui/Table";
import { usePagedData } from "components/ui/Pagination/usePagedData";
import usePortfolioTableColumns from "./useOpenOrdersTableColumns";
import { useSelector } from "react-redux";
import { userSelector } from "utils/redux/user/userSlice";
import { useQueryClient } from "@tanstack/react-query";
import Order from "models/Order";
import { toast } from "react-hot-toast";

interface Props { }

const Portfolio: React.FC<Props> = () => {
  const user = useSelector(userSelector);
  const queryClient = useQueryClient();

  const pagedData = usePagedData<Order>(
    ["orders", user!.id],
    `orders?investorId=${user!.id}`,
    !!user
  );

  const onCancelSuccess = () => {
    toast.success("Order is cancelled");

    queryClient.invalidateQueries({ queryKey: ["orders"] });
  };
  const onCancelError = () => {
    toast.error("Failed to cancel the order");
  };
  const columns = usePortfolioTableColumns({ onCancelSuccess, onCancelError });

  return (
    <Card>
      <Table
        {...pagedData}
        columns={columns}
      />
    </Card>
  );
};

export default Portfolio;