import Card from "components/Layout/Card";
import React from "react";
import { Table } from "components/ui/Table";
import { usePagedData } from "components/ui/Pagination/usePagedData";
import usePortfolioTableColumns from "./useTradesTableColumns";
import { useRouter } from "next/router";
import { useSelector } from "react-redux";
import { userSelector } from "utils/redux/user/userSlice";
import Trade from "models/Trade";

interface Props { }

const Portfolio: React.FC<Props> = () => {
  const router = useRouter();
  const user = useSelector(userSelector);
  const pagedData = usePagedData<Trade>(
    ["trades", user?.id || ""],
    `trades?investorId=${user?.id}`,
    !!user?.id
  );

  const columns = usePortfolioTableColumns();

  return (
    <Card>
      <Table
        {...pagedData}
        columns={columns}
      />
    </Card>
  );
};

export default Portfolio;
