import api from "@/api";
import { useMutation } from "@tanstack/react-query";
import { ColumnDef, Row } from "@tanstack/react-table";
import { StatusBadge, StatusBadgeColor } from "components/ui/Badge";
import Button from "components/ui/Button";
import { InvestorMaturities } from "models/Investor";
import { useMemo } from "react";
import { useSelector } from "react-redux";
import consts from "utils/consts";
import { userSelector } from "utils/redux/user/userSlice";

const getStatusColor = (status: InvestorMaturities["status"]): StatusBadgeColor => {
  switch (status) {
    case "recorded":
    case "distributing":
    case "collecting":
      return "yellow";
    default:
      return "green";
  }
};

interface MaturityButtonProps {
  row: Row<InvestorMaturities>;
  onMaturitySuccess: () => void;
}

const MaturityButton = ({ row, onMaturitySuccess }: MaturityButtonProps) => {
  const user = useSelector(userSelector);
  
  const acceptMaturityMutation = useMutation(
    async (bondId: string) => {
      await api.put(`bonds/${bondId}/investors/${user!.id}/maturity/payments/accept`);
    },
    {
      onSuccess: () => onMaturitySuccess()
    }
  );

  return (
    <Button 
      className="mr-[440px] w-[350px]" 
      loading={acceptMaturityMutation.isLoading}
      onClick={() => acceptMaturityMutation.mutate(row.original.bond.id)}
    >
      Accept {row.original.payout_amount} {consts.defaultCurrencySymbol} by returning {row.original.bond_amount}
    </Button>
  );
}

const useMaturitiesTableColumns = (balance: number, onMaturitySuccess: () => void) => {
  const columns = useMemo(() => {
    const cols: ColumnDef<InvestorMaturities>[] = [
      {
        id: "bondIsin",
        header: "ISIN",
        accessorKey: "bond.ISIN",
        size: 277,
      },
      {
        id: "name",
        header: "Name",
        accessorKey: "bond.token.name",
        size: 277,
      },
      {
        id: "status",
        header: "Status",
        size: 200,
        cell: ({ row }: { row: Row<InvestorMaturities> }) => <StatusBadge color={getStatusColor(row.original.bond.status)} text={row.original.bond.status}></StatusBadge>
      },
      {
        id: "balance",
        header: "",
        accessorKey: "balance",
        size: 350,
        cell: ({ row }: { row: Row<InvestorMaturities> }) => (
          row.original.token_swap_id && <MaturityButton row={row} onMaturitySuccess={onMaturitySuccess} />
        )
      },
    ];

    return cols;
  }, [balance]);

  return columns;
};

export default useMaturitiesTableColumns;
