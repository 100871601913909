import Card from "components/Layout/Card";
import React from "react";
import { Table } from "components/ui/Table";
import { usePagedData } from "components/ui/Pagination/usePagedData";
import usePortfolioTableColumns from "./useAssetsTableColumns";
import useDistributionsTableColumns from "./useDistributionsTableColumns";
import useMaturitiesTableColumns from "./useMaturitiesTableColumns";
import { useSelector } from "react-redux";
import { userSelector } from "utils/redux/user/userSlice";
import { InvestorDistributions, InvestorMaturities, InvestorSecurities } from "models/Investor";
import { balanceSelector } from "utils/redux/user/balanceSlice";
import { useQueryClient } from "@tanstack/react-query";
import toast from "react-hot-toast";

interface Props { }

const Portfolio: React.FC<Props> = () => {
  const user = useSelector(userSelector);
  const balance = useSelector(balanceSelector);
  const queryClient = useQueryClient();

  const userId = user?.id || "";

  // distributions
  const onDistributionSuccess = () => {
    toast.success("Bond accepted.");

    queryClient.invalidateQueries({ queryKey: ["balance"] });
    queryClient.invalidateQueries({ queryKey: ["distributions"] });
    queryClient.invalidateQueries({ queryKey: ["my-portfolio"] });
  };
  const distributionData = usePagedData<InvestorDistributions>(
    ["distributions", userId],
    `investors/${userId}/distributions?status=noFunds`,
    !!userId
  );
  const distributionColumns = useDistributionsTableColumns(balance, onDistributionSuccess);

  // maturity
  const onMaturitySuccess = () => {
    toast.success("Bond maturity accepted.");

    queryClient.invalidateQueries({ queryKey: ["balance"] });
    queryClient.invalidateQueries({ queryKey: ["maturities"] });
    queryClient.invalidateQueries({ queryKey: ["my-portfolio"] });
  };
  const maturityData = usePagedData<InvestorMaturities>(
    ["maturities", userId],
    `investors/${userId}/maturity/payments?status=noFunds`,
    !!userId
  );
  const maturityColumns = useMaturitiesTableColumns(balance, onMaturitySuccess);

  // portfolio
  const portfolioData = usePagedData<InvestorSecurities>(
    ["my-portfolio", userId],
    `investors/${userId}/portfolio`,
    !!userId
  );
  const portfolioColumns = usePortfolioTableColumns();

  const isAnythingToAccept = distributionData.pagination.totalObjects > 0 || maturityData.pagination.totalObjects > 0;

  return (
    <>
    { distributionData.pagination.totalObjects > 0 && <Card className="my-5">
      <Table {...distributionData} columns={distributionColumns} />  
    </Card> }

    { maturityData.pagination.totalObjects > 0 && <Card className="my-5">
      <Table {...maturityData} columns={maturityColumns} />  
    </Card> }

    { !isAnythingToAccept && <Card className="my-5">
      <Table {...portfolioData} columns={portfolioColumns} />  
    </Card> }
    </>
  )
};

export default Portfolio;
